<template><!--  -->
<div>

  <TransitionHelper v-model:open="mobileMenuOpen" direction="right">
    <MobileMenu />
  </TransitionHelper>

  <TransitionHelper v-model:open="mobileSearchOpen" direction="left">
    <SearchField class="" :is-mobile="true" @close="() => mobileSearchOpen = !mobileSearchOpen" />
  </TransitionHelper>

  <header ref="header" class="relative border-b border-ui-border lg:border-none min-h-[68px]">
    <nav aria-label="Top">
      <div class="max-w-medium mx-auto p-4 lg:p-6  bg-white w-full" :class="slideStyle">
        <div class="flex items-center justify-between">
          <Link :href="`/${$page.props.locale}`" aria-label="frontpage"
            class="hidden lg:flex lg:items-center py-1 lg:py-0 flex-shrink-0">
          <LogoIcon class="w-[235px]" />
          </Link>
          <div class="flex gap-2 lg:gap-6 justify-center items-center lg:w-full">
            <div class="flex items-center lg:hidden">
              <button type="button" class="px-[7px] py-[9px] rounded-md  " aria-label="open menu"
                @click="openMobilemenu">
                <MenuIcon class="w-full min-w-[14px] min-h-2.5 max-w-[24px] max-h-[30px] flex-shrink" />
              </button>
            </div>
            <SearchField  v-if="!device.isDesktop" />
          </div>
          <div class="flex flex-grow mx-2 sm:mx-0">
            <Link :href="`/${$page.props.locale}`" class="lg:hidden flex-shrink mx-auto" aria-label="frontpage">
            <LogoIcon class="w-full sm:w-[176.25px]" height="24px" />
            </Link>
          </div>
          <div class="flex items-center justify-end lg:ml-0 gap-2 lg:gap-4">
            <div v-if="device.isDesktop" class="hidden lg:flow-root lg:relative flex-shrink-0">
              <NavDropDownMenu />
            </div>
            <div v-if="device.isDesktop" class="hidden lg:flow-root lg:relative flex-shrink-0">
              <div class="group -m-2 p-1 flex items-center hover:cursor-pointer justify-center"
                @click="navSettingsOpen = !navSettingsOpen">
               
                  <div class="text-xs uppercase flex items-center justify-center"><span v-if="$page.props.locale?.toUpperCase() == 'FI'" class="ml-2">
   
   <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 18 11">
   <rect width="18" height="11" fill="#fff"/>
   <path d="M0,5.5h18M6.5,0v11" stroke="#002F6C" stroke-width="3"/>
   </svg>
    </span> 
    <span v-else class="ml-2"> 
     <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 1300 650">
   <defs>
   <polygon id="pt" points="-0.1624598481164531,0 0,-0.5 0.1624598481164531,0" transform="scale(0.0616)" fill="#FFF"/>
   <g id="star">
     <use xlink:href="#pt" transform="rotate(-144)"/>
     <use xlink:href="#pt" transform="rotate(-72)"/>
     <use xlink:href="#pt"/>
     <use xlink:href="#pt" transform="rotate(72)"/>
     <use xlink:href="#pt" transform="rotate(144)"/>
   </g>
   <g id="s5">
     <use xlink:href="#star" x="-0.252"/>
     <use xlink:href="#star" x="-0.126"/>
     <use xlink:href="#star"/>
     <use xlink:href="#star" x="0.126"/>
     <use xlink:href="#star" x="0.252"/>
   </g>
   <g id="s6">
     <use xlink:href="#s5" x="-0.063"/>
     <use xlink:href="#star" x="0.315"/>
   </g>
   <g id="x4">
     <use xlink:href="#s6"/>
     <use xlink:href="#s5" y="0.054"/>
     <use xlink:href="#s6" y="0.108"/>
     <use xlink:href="#s5" y="0.162"/>
   </g>
   <g id="u">
     <use xlink:href="#x4" y="-0.216"/>
     <use xlink:href="#x4"/>
     <use xlink:href="#s6" y="0.216"/>
   </g>
   <rect id="stripe" width="1300" height="50" fill="#B22234"/>
   </defs>
   <rect width="1300" height="650" fill="#FFF"/>
   <use xlink:href="#stripe"/>
   <use xlink:href="#stripe" y="100"/>
   <use xlink:href="#stripe" y="200"/>
   <use xlink:href="#stripe" y="300"/>
   <use xlink:href="#stripe" y="400"/>
   <use xlink:href="#stripe" y="500"/>
   <use xlink:href="#stripe" y="600"/>
   <rect width="494" height="350" fill="#3C3B6E"/>
   <use xlink:href="#u" transform="translate(247,175) scale(650)"/>
   <svg viewBox="0 0 60 30" width="1300" height="650">
     <clipPath id="t">
       <path d="M30,15L60,30V15H0V30H30z"/>
     </clipPath>
     <clipPath id="uk">
       <path d="M0,30H60V0z"/>
     </clipPath>
     <g clip-path="url(#uk)">
       <path d="M0,0 v30 h60 v-30 z" fill="#00247d"/>
       <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" stroke-width="6"/>
       <path d="M0,30 L30,15 L60,30" clip-path="url(#t)" fill="none" stroke="#cf142b" stroke-width="4"/>
       <path d="M30,0 v30 M0,15 h60" stroke="#fff" stroke-width="10"/>
       <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" stroke-width="6"/>
     </g>
   </svg>
   </svg>
     
   
   </span></div>
                
                <div class="ml-2">
                  <ChevronDown class=" " :class="{ 'rotate-180': navSettingsOpen }" />
                </div>
              </div>
              <NavSettingsDropdown v-if="navSettingsOpen" @close="navSettingsOpen = false" />
            </div>
            <div v-if="device.isDesktop" class="hidden lg:flow-root lg:relative flex-shrink-0  cursor-pointer">
              <div class="lg:flex">
                <SearchField  />
              </div>
            </div>
            <div class="hidden lg:flow-root lg:relative flex-shrink-0">
              <component :is="(isLoggedIn) ? 'Link' : 'span'" class="group flex flex-row items-center cursor-pointer"
                :href="accountLink" @click.prevent="toggleLoginSidemenu">
                <AccountIconLoggedIn v-if="(isLoggedIn)" class="flex-shrink-0  " />
                <AccountIcon v-else class="flex-shrink-0  " />
              </component>
            </div>
            <div class="flow-root lg:hidden lg:relative flex-shrink cursor-pointer">
              <component :is="(isLoggedIn) ? 'Link' : 'span'" class="group flex flex-row items-center"
                :href="accountLink" @click.prevent="toggleLoginSidemenu">
                <AccountIconLoggedIn v-if="(isLoggedIn)" class="flex-shrink-0  " />
                <AccountIcon v-else class="flex-shrink-0  " />
              </component>
            </div>
            <div class="flow-root lg:relative flex-shrink lg:flex-shrink-0">
              <button class="relative group flex items-center" aria-label="Open cart"
                @click="() => toggleCartSidemenu()">
                <CartIcon class="w-full min-w-6 min-h-6 max-w-8 max-h-8" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <NavMenu />
    </nav>
  </header>
</div>
</template>

<script setup>
import { shallowRef, computed, defineAsyncComponent, provide, inject, onMounted, onBeforeUnmount } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useAccount } from "@/Composables/Account.js";
import { useDevice } from "g/Composables/GlobalDevice.js";
import throttle from 'lodash/throttle';
import AccountIcon from "@/Icons/AccountIcon.vue";
import AccountIconLoggedIn from "@/Icons/AccountIconLoggedIn.vue";
import LogoIcon from "@/Icons/LogoIcon.vue";
import CartIcon from "@/Icons/CartIcon.vue";
import MenuIcon from "@/Icons/MenuIcon.vue";
import SearchField from "@/Components/Nav/SearchField.vue";
import TransitionHelper from "@/Shared/TransitionHelper.vue";
import NavMenu from "@/Components/Nav/NavMenu.vue";

const ChevronDown = defineAsyncComponent(() => import("@/Icons/ChevronDown.vue"));
const MobileMenu = defineAsyncComponent(() => import("@/Components/Nav/MobileMenu.vue"));
const NavSettingsDropdown = defineAsyncComponent(() => import("@/Components/Nav/NavSettingsDropdown.vue"));
const NavDropDownMenu = defineAsyncComponent(() => import("@/Components/Nav/NavDropDownMenu.vue"));
// const SearchField = defineAsyncComponent(() => import("@/Components/Nav/SearchField.vue"));

const { device } = useDevice();
const { isLoggedIn } = useAccount();
const toggleCartSidemenu = inject('toggleCartSidemenu');
const toggleLoginSidemenu = inject('toggleLoginSidemenu');
const navSettingsOpen = shallowRef(false);
const mobileSearchOpen = shallowRef(false);
const mobileMenuOpen = shallowRef(false);
const $page = usePage();

const accountLink = computed(() => {
  const locale = $page.props.locale;
  return isLoggedIn.value ? `/${locale}/account` : '#';
});

function openMobilemenu() {
  mobileMenuOpen.value = true;
}
function closeMobilemenu() {
  mobileMenuOpen.value = false;
}

provide('closeMobilemenu', closeMobilemenu);

const searchTerm = shallowRef("");
const searchbarVisible = shallowRef(false);
const quicksearchProducts = shallowRef([]);
provide('SearchDropdown', {
  searchTerm,
  searchbarVisible,
  quicksearchProducts,
});

const header = shallowRef(null);
const headerFixed = shallowRef(null);
const lastScrollTop = shallowRef(0);
const handleScroll = () => {
  var st = window.scrollY || header.value?.scrollTop;
  if (st > lastScrollTop.value) {
    // console.log('down');
    if (headerFixed.value) {
      headerFixed.value = null;
    }
  } else if (st < lastScrollTop.value) {
    // console.log('up');
    if (st == 0) {
      headerFixed.value = null;
    } else if (!headerFixed.value) {
      headerFixed.value = true;
    }
  } // else was horizontal scroll
  lastScrollTop.value = st <= 0 ? 0 : st; // For Mobile or negative scrolling
};

const slideStyle = computed(() => {
  if (!device.isDesktop) {
    return (headerFixed.value) ? 'appear-from-top' : 'hide-to-top';
  }
  return '';
});

onMounted(() => {
  if (!device.isDesktop) {
    window.addEventListener('scroll', throttle(handleScroll, 200));
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

</script>

<style scoped>
.appear-from-top {
  animation-name: fadeInUp;
  animation-duration: .3s;
  @apply border-b border-ui-border fixed z-10
}

@keyframes fadeInUp {
  from {
    position: relative;
    opacity: 0;
    top: -68px;
  }

  to {
    position: fixed;
    opacity: 1;
    top: 0;
  }
}

/* .hide-to-top {
  animation-name: fadeInDown;
  animation-duration: .3s;
}

@keyframes fadeInDown {
  from {
    position: fixed;
    opacity: 1;
    top: 0;
  }

  to {
    position: relative;
    opacity: 0;
    top: -68px;
  }

} */
</style>
