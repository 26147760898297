<template>
<div class="max-w-large mx-auto bg-accent-dark text-white">

  <div class="max-w-default flex flex-col space-y-10 py-8 mx-auto px-4 lg:px-12">

    <div class="grid grid-cols-1 md:grid-cols-4 gap-8 gap-y-10">
      <FooterMenu :items="items" :row="'first'"  />
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 gap-y-10">
      <FooterMenu :items="items2" :row="'second'" />
    </div>

    <div class="lg:pt-10">
      <Link :href="`/${$page.props.locale}`" aria-label="frontpage">
      <LogoIconWhite class="h-10 w-full" />
      </Link>
    </div>

    <div class="pt-6 border-t border-black ">
      <FooterInfo />
    </div>

  </div>
</div>
</template>

<script setup>
import { computed, provide } from "vue";
import { useStore } from "g/Composables/GlobalStore.js";
import FooterMenu from "@/Shared/FooterMenu.vue";
import FooterInfo from "@/Shared/FooterInfo.vue";
import LogoIconWhite from "@/Icons/LogoIconWhite.vue";

const TYPES = Object.freeze({
  MENU_TEXT: 'menu-text',
  MENU_LINK: 'menu-link',
  SOME: 'social-media',
  COMPONENT: 'component',
  ARTICLE: 'article',
});

const store = useStore();

const items = computed(() => store['footer']?.slice(0, 4)) ?? [];
const items2 = computed(() => store['footer'].slice(4)) ?? [];

const trim = (str) => str.trim();

provide('Footer', {
  TYPES,
  store,
  trim,
});


</script>
