<template>
<div class="hidden lg:block bg-accent-dark text-white">
  <div class="max-w-medium mx-auto lg:flex px-7"> 
    <div>
      <div class="h-full flex justify-center space-x-8">
        <template v-for="item in store['main']" :key="item.number">
          <div class="flex relative z-40" @click="toggleActive(item)">
            <Link v-if="item.target_type === 'menu-link'" :href="item.value"
              class="flex items-center text-sm font-sans py-4">
            {{ item.name }}
            </Link>
            <div v-else class="flex gap-2 items-center text-sm font-sans cursor-pointer py-4"
              :class="{ '': activeRootLevelNumber == item.number }">
              <div>
                {{ item.name }}
              </div>
              <ChevronDown v-if="item.children.length" :class="{ 'rotate-180': false }" class="text-white fill-white "/>
            </div>
          </div>
        </template>
      </div>
    </div>
    <MegaMenu v-if="activeMegamenu" :active-menu="activeMegamenu" @hide="activeMegamenu = false" />
  </div>
</div>
</template>
<script setup>

import { ref, onBeforeMount, computed } from "vue";
import { router, usePage } from '@inertiajs/vue3';
import { useStore } from "g/Composables/GlobalStore.js";
import MegaMenu from "@/Shared/MegaMenu.vue";
import ChevronDown from "@/Icons/ChevronDown.vue";
const store = useStore();
const hamburgerMenuOpen = ref(false);
const activeMegamenu = ref(false);
const $page = usePage();
const activeRootLevelNumber = computed(() => {
  const breadcrumbs = $page.props.breadcrumbs ?? [];
  const numbers = breadcrumbs.map(item => item.number);
  return numbers[1] ?? null;
});
const toggleActive = (item) => {
  if (item && item.children.length) {
    activeMegamenu.value = activeMegamenu.value === item.id ? false : item.id;
  } else {
    activeMegamenu.value = false;
  }
};
const close = () => {
  setTimeout(() => {
    activeMegamenu.value = false;
    hamburgerMenuOpen.value = false;
  }, 100);
};
onBeforeMount(function () {
  router.on('start', close);
});

</script>
