<template>
<div class="z-10 fixed inset-0 transition-opacity">
  <div class="absolute inset-0 bg-black bg-opacity-30 cursor-pointer" tabindex="0" @click="close"> </div>
</div>
<div class="absolute max-w-medium mx-auto top-full inset-x-0 z-20">
  <div class="relative lg:flex px-6 max-h-[80vh] overflow-x-auto bg-accent-dark text-white shadow-2xl">
    <div class="max-w-medium mx-auto w-full place-items-start gap-y-5 pt-6 pb-20 px-4">
      <div class="grid grid-cols-2 w-full">
        <div class="flex flex-col justify-start  font-roboto"
          :class="[isSubmenu ? 'text-base' : 'text-2xl']">
          <div v-if="isSubmenu" class="flex gap-4 items-center font-sans  mb-6 cursor-pointer" @click="back">
            <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.1199 0.5L21.4238 1.82308L13.8578 9.5L21.4238 17.1769L20.1199 18.5L11.2499 9.5L20.1199 0.5Z"
                fill="currentColor" />
              <path
                d="M8.86986 0.5L10.1738 1.82308L2.60784 9.5L10.1738 17.1769L8.86986 18.5L-8.39233e-05 9.5L8.86986 0.5Z"
                fill="currentColor" />
            </svg>
            <div>
              {{ trans('back_btn') }}
            </div>
          </div>
          <Link v-if="selectedMenuItem.target_type != 'menu-text'" class="flex gap-4 items-center font-sans uppercase mb-4" :href="selectedMenuItem.value">
          {{ trans('all') }} {{ selectedMenuItem.name }}
          </Link>
          <div v-for="item in selectedMenuItem.children" :key="item.id">
            <Link v-if="item.children.length === 0" class="flex gap-4 items-center font-sans uppercase mb-4"
              :href="item.value">
            <div>{{ item.name }}</div>
            </Link>
            <div v-else class="flex gap-4  items-center font-sans uppercase mb-4 cursor-pointer" @click="navigate(item)">
              <div>{{ item.name }}</div>
              <MenuArrowIcon />
            </div>
          </div>
        </div>
        <MenuBanner :item="firstParentMenu" />
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "g/Composables/GlobalStore.js";
import MenuArrowIcon from "@/Icons/MenuArrowIcon.vue";
import MenuBanner from "@/Components/Nav/MenuBanner.vue";

const props = defineProps({
  activeMenu: {
    type: [Number, Boolean],
    required: true,
  },
});

const store = useStore();

const firstParentMenu = computed(() => {
  return store['main'].find(item => item.id === props.activeMenu);
});

const parentMenus = ref([firstParentMenu.value]);

watch(() => props.activeMenu, () => parentMenus.value = [firstParentMenu.value]);

const isSubmenu = computed(() => parentMenus.value.length > 1);

const selectedMenuItem = computed(() => {
  const lastParentMenu = parentMenus.value[parentMenus.value.length - 1];
  return lastParentMenu || [];
});

function navigate(item) {
  parentMenus.value.push(item);
}

function back() {
  if (parentMenus.value.length) {
    parentMenus.value.pop();
  }
}

const emit = defineEmits(['hide']);
const close = () => emit('hide');

</script>
