<template>
  <svg width="32" height="32"  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_975_2996)">
      <path d="M22.2199 19.5601C21.8299 19.1701 21.1999 19.1701 20.8099 19.5601L19.5499 20.8201C19.1599 21.2101 19.1599 21.8401 19.5499 22.2301L20.1799 22.8601L22.8499 20.1901L22.2199 19.5601Z" fill="#1C1B1F"/>
      <path d="M23.5095 20.8589L20.8367 23.5317L22.1731 24.8682L24.846 22.1953L23.5095 20.8589Z" fill="#1C1B1F"/>
      <path d="M25.52 22.8599L22.85 25.5299L23.52 27.5399C25.07 26.4299 26.42 25.0799 27.53 23.5299L25.52 22.8599Z" fill="#1C1B1F"/>
      <path d="M21.99 13.5002C21.99 11.2302 21.11 9.09023 19.5 7.48023C16.18 4.16023 10.78 4.16023 7.46998 7.48023C4.14998 10.8002 4.14998 16.2002 7.46998 19.5102C9.12998 21.1702 11.31 22.0002 13.49 22.0002C15.67 22.0002 17.85 21.1702 19.51 19.5102C21.17 17.8502 22 15.7702 22 13.4902L21.99 13.5002ZM8.87998 18.1102C6.33998 15.5702 6.33998 11.4402 8.87998 8.90023C10.15 7.63023 11.82 7.00023 13.48 7.00023C15.14 7.00023 16.81 7.63023 18.08 8.90023C19.31 10.1302 19.99 11.7602 19.99 13.5002C19.99 15.2402 19.31 16.8702 18.08 18.1002C15.54 20.6402 11.41 20.6402 8.86998 18.1002L8.87998 18.1102Z" fill="#1C1B1F"/>
    </g>
    <defs>
      <clipPath id="clip0_975_2996">
        <rect width="22.55" height="22.54" fill="white" transform="translate(4.97998 5)"/>
      </clipPath>
    </defs>
  </svg>
</template>
