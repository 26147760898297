<template>
<div v-if="firstParentMenu?.picture" class="flex flex-col space-y-10 lg:space-x-4 items-start">
  <div class="flex flex-col space-y-6 lg:space-x-4 h-full">
    <div class="font-roboto text-baseminus leading-5">{{ firstParentMenu.description }}</div>
    <div class="flex">
      <Link :href="firstParentMenu.value"
        class="btn-primary flex space-x-2 flex-shrink-0 justify-center items-center px-6 py-3 uppercase">
      <span class="uppercase text-ui-yellowish font-bebas text-lg">
        {{ trans('shop_btn') }} {{ firstParentMenu.name }}
      </span>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_2683_19085" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25"
          height="24">
          <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2683_19085)">
          <path d="M14.5 18L13.1 16.55L16.65 13H4.5V11H16.65L13.1 7.45L14.5 6L20.5 12L14.5 18Z" fill="#F8F7E5" />
        </g>
      </svg>
      </Link>
    </div>
  </div>
  <img :src="firstParentMenu.picture.pictureUrl" class="object-cover aspect-square max-h-[400px] shadow-lg"
    loading="lazy" :alt="firstParentMenu.name">
</div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const SSR = import.meta.env.SSR;

const firstParentMenu = computed(() => {
  const item = props.item;
  if (!SSR && item && !item.descriptionParsed) { // Smart? No
    let parser = new DOMParser().parseFromString(item.description, 'text/html');
    const description = parser.querySelector('p')?.textContent;
    item.description = description;
    item.descriptionParsed = true;
  }
  return item;
});

</script>
