<template><!-- Overlay fades-->

<Transition enter-from-class="opacity-0" leave-to-class="opacity-0" enter-active-class="transition duration-300"
  leave-active-class="transition duration-300">
  <div v-if="open" class="z-10 fixed inset-0 transition-opacity" @click="hide">
    <div class="absolute inset-0  bg-black bg-opacity-30" tabindex="0"></div>
  </div>
</Transition>

<!-- Content slides -->
<Transition :enter-from-class="direction === 'right' ? '-translate-x-full opacity-0' : 'translate-x-full opacity-0'"
  :leave-to-class="direction === 'right' ? '-translate-x-full opacity-0' : 'translate-x-full opacity-0'"
  enter-active-class="transition duration-300" leave-active-class="transition duration-300">
  <template v-if="open">
    <slot />
  </template>
</Transition>
</template>

<script setup>
import { useScrollbar } from "@/Composables/Scrollbar.js";

defineProps({
  direction: {
    type: String,
    required: true,
  },
});

const open = defineModel('open', { type: Boolean, default: false });

const { hide } = useScrollbar(open);

</script>
