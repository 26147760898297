<template>
<svg height="32" viewBox="0 0 235 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1780_5758)">
<path d="M29.9063 7.56675L29.2263 4.8112L26.0727 6.67851L24.9525 3.44857L21.2569 6.00224L18.0934 2.76221L14.93 6.00224L11.2343 3.44857L10.1141 6.67851L6.96053 4.8112L6.28053 7.56675L3.77406 6.38244L3.70508 8.7477C10.3474 6.00225 18.0934 6.16038 18.0934 6.16038C18.0934 6.16038 25.8395 5.99888 32.4851 8.7477L32.4161 6.38244L29.9096 7.56675H29.9063Z" fill="#903221"/>
<path d="M18.0932 9.60276C17.9355 9.5994 14.6275 9.57585 12.8438 9.96277C12.8438 9.96277 16.8482 11.6215 18.0932 18.0679C19.3415 11.6248 23.3459 9.96277 23.3459 9.96277C21.5589 9.57585 18.2542 9.5994 18.0932 9.5994V9.60276Z" fill="#903221"/>
<path d="M37.6955 0H0V31.9966H37.6922V0H37.6955ZM18.0939 30.1596C17.1018 30.1596 14.9402 29.8871 15.2063 29.6852C15.5644 29.4127 15.6005 27.3435 15.6005 27.3435H20.5905C20.5905 27.3435 20.6233 29.416 20.9847 29.6852C21.2507 29.8871 19.0892 30.1596 18.0971 30.1596H18.0939ZM18.0939 24.376H20.5905V26.2938H15.6005V24.376H18.0939ZM15.5972 23.333V21.9737C15.5972 21.2773 16.3594 21.3479 16.3594 21.3479H19.8251C19.8251 21.3479 20.5872 21.2773 20.5872 21.9737V23.333H15.5972ZM30.5966 9.93544C20.078 11.6816 20.4098 20.2746 20.4098 20.2746H18.0906H15.7746C15.7746 20.2746 16.1064 11.6816 5.58781 9.93544C4.85196 9.81432 13.1039 7.91336 17.1872 7.95374L18.0906 7.94028L18.9939 7.95374C23.0772 7.91336 31.3325 9.81769 30.5933 9.93881L30.5966 9.93544ZM34.0492 7.37167C33.967 8.07823 33.1096 9.3702 33.1096 9.3702C32.722 9.83787 32.0157 9.52161 32.0157 9.52161C25.4556 6.84008 18.0939 7.03186 18.0939 7.03186C18.0939 7.03186 10.7321 6.84344 4.17525 9.52161C4.17525 9.52161 3.47226 9.83787 3.08134 9.3702C3.08134 9.3702 2.22067 8.07823 2.14183 7.37167C2.06956 6.73241 2.47361 6.39933 2.47361 6.39933C2.70685 6.20755 7.61138 2.09946 18.0971 2.00526C28.5829 2.09946 33.4874 6.20755 33.7207 6.39933C33.7207 6.39933 34.128 6.72905 34.0524 7.37167H34.0492Z" fill="#903221"/>
<path d="M55.4704 31.9727L61.7415 0.0400391H56.2884L51.9324 24.9308L47.6061 0.0400391H42.1562L48.3189 31.9727H55.4704Z" fill="#1D1D1D"/>
<path d="M94.528 12.297V7.66069C94.528 5.34926 93.9302 3.54924 92.741 2.25726C91.3843 0.780235 89.2556 0.0400391 86.3582 0.0400391H78.1621V31.9727H83.359V19.9446H86.8247L89.9783 31.9727H95.599L91.7489 18.0302C93.6017 16.469 94.528 14.558 94.528 12.297ZM89.4691 12.4013C89.4691 13.1651 89.18 13.8178 88.6019 14.3628C88.0237 14.9045 87.324 15.177 86.4962 15.177H83.359V4.95897H86.4962C88.477 4.95897 89.4658 5.88422 89.4658 7.73807V12.4013H89.4691Z" fill="#1D1D1D"/>
<path d="M107.141 24.4127C107.141 25.1697 106.852 25.8123 106.274 26.3405C105.696 26.8688 104.996 27.1346 104.172 27.1346C103.347 27.1346 102.641 26.8721 102.053 26.3405C101.465 25.8123 101.172 25.1697 101.172 24.4127V0.0400391H96.084V24.4598C96.084 26.5424 96.8822 28.3155 98.4755 29.7791C100.072 31.2393 102.013 31.9727 104.306 31.9727C106.599 31.9727 108.524 31.2494 110.137 29.8026C111.75 28.3592 112.558 26.576 112.558 24.4631V0.0400391H107.141V24.4093V24.4127Z" fill="#1D1D1D"/>
<path d="M129.373 19.3894C128.676 18.2623 127.684 17.1184 126.403 15.9576C126.019 15.5942 125.221 14.955 124.012 14.0432C122.987 13.2794 122.215 12.6166 121.702 12.0548C120.346 10.5777 119.669 9.12762 119.669 7.70106C119.669 6.78928 119.869 6.02553 120.273 5.41319C120.769 4.64944 121.492 4.26925 122.448 4.26925C123.345 4.26925 124.035 4.52495 124.511 5.03972C125.171 5.753 125.575 6.7489 125.72 8.02406L130.7 7.20311C130.572 5.84384 130.191 4.64271 129.56 3.59634C128.926 2.54997 128.098 1.73912 127.07 1.16043C125.749 0.413501 124.163 0.0400391 122.31 0.0400391C120.457 0.0400391 118.772 0.480792 117.468 1.3623C115.359 2.8225 114.304 4.96907 114.304 7.80873C114.304 9.38332 114.689 10.8469 115.457 12.1893C116.174 13.3837 117.392 14.7363 119.117 16.247C121.17 18.0066 122.711 19.3255 123.739 20.207C125.04 21.4855 125.694 22.7674 125.694 24.0627C125.694 24.9611 125.519 25.6474 125.171 26.1286C124.491 27.1076 123.585 27.6123 122.448 27.646C121.64 27.6796 120.924 27.3869 120.303 26.7712C119.459 25.957 118.926 24.6381 118.706 22.8212L113.756 23.1442C113.868 24.8029 114.245 26.2732 114.899 27.5585C115.549 28.8437 116.436 29.8565 117.553 30.6034C118.89 31.5152 120.579 31.9694 122.616 31.9694C123.716 31.9694 124.679 31.855 125.503 31.6228C127.044 31.1922 128.236 30.4553 129.08 29.409C130.365 27.8647 130.989 26.0108 130.95 23.8373C130.93 22.9592 130.674 21.9633 130.181 20.853C129.981 20.4055 129.714 19.9177 129.383 19.3861L129.373 19.3894Z" fill="#1D1D1D"/>
<path d="M147.648 0.0400391H131.42V4.88159H136.949V31.9727H142.145V4.88159H147.648V0.0400391Z" fill="#1D1D1D"/>
<path d="M163.721 27.1348H154.122V18.4342H161.136V13.5993H154.122V4.89532H163.721V0.0571289H148.922V31.973H163.721V27.1348Z" fill="#1D1D1D"/>
<path d="M170.196 0.0400391H164.996V31.9727H179.164V27.1817H170.196V0.0400391Z" fill="#1D1D1D"/>
<path d="M180.439 31.9862H195.238V27.1447H185.636V18.4339H192.653V13.5923H185.636V4.88159H195.238V0.0400391H180.439V31.9862Z" fill="#1D1D1D"/>
<path d="M213.607 0.0400391H208.065L201.712 11.4323V0.0400391H196.512V31.9997H201.712V21.8657L203.266 19.0227L208.725 31.9997H214.836L206.492 13.0742L213.607 0.0400391Z" fill="#1D1D1D"/>
<path d="M71.3624 0.0400391H63.4225L57.9004 31.9727H62.8805L63.8167 26.179H70.4721L71.4084 31.9727H76.8812L71.3624 0.0400391ZM64.5098 21.8455L67.1247 5.69581L69.7363 21.8455H64.5098Z" fill="#1D1D1D"/>
<path d="M221.542 0.0634766L216.02 31.9995H221L221.936 26.2058H228.591L229.528 31.9995H235L229.482 0.0634766H221.542ZM222.626 21.8723L225.241 5.72261L227.852 21.8723H222.626Z" fill="#1D1D1D"/>
</g>
<defs>
<clipPath id="clip0_1780_5758">
<rect width="235" height="32" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
