<template>

  <Head>
    <title head-key="title">{{ metadata.title }}</title>
  </Head>

  <Head v-for="(item, key) in metadata.metatags" :key="key">
    <meta :head-key="key" :name="item.name" :property="item.property" :content="item.content" />
  </Head>

  <Head v-if="metadata.canonical">
    <link head-key="canonical" rel="canonical" :href="metadata.canonical" />
  </Head>

  <Head>
    <component :is="'script'" v-if="$page.props.jsonld" type="application/ld+json">
      {{ $page.props.jsonld }}
    </component>
  </Head>

</template>

<script>
import { Head } from '@inertiajs/vue3';
export default {
  components: { Head },
  props: {
    metadata: {
      type: Object,
      required: false,
      default: {
        title: '',
        metatags: [],
      },
    },
  },
};
</script>
