<template><svg viewBox="0 0 388 53" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="2" y="1" width="57" height="51" fill="white" />
  <path
    d="M49.6591 12.6472L48.5279 8.10449L43.2893 11.183L41.4284 5.85381L35.288 10.0654L30.0338 4.7207L24.7795 10.0654L18.6391 5.85381L16.7782 11.183L11.5396 8.10449L10.4084 12.6472L6.24358 10.6915L6.12891 14.5927C17.1639 10.0654 30.039 10.3241 30.039 10.3241C30.039 10.3241 42.9088 10.0603 53.9542 14.5927L53.8396 10.6915L49.6747 12.6472H49.6695H49.6591Z"
    fill="#903221" />
  <path
    d="M30.0214 16.0095C29.7608 16.0043 24.2615 15.9629 21.3008 16.6045C21.3008 16.6045 27.9572 19.3415 30.0214 29.974C32.096 19.3467 38.7472 16.6045 38.7472 16.6045C35.7761 15.9681 30.2873 16.0043 30.0214 16.0043V16.0095Z"
    fill="#903221" />
  <path
    d="M0 0V52.7746H60.054V0M30.0661 49.7427C28.4189 49.7427 24.8275 49.2926 25.2653 48.9614C25.8596 48.5113 25.9221 45.1016 25.9221 45.1016H34.2153C34.2153 45.1016 34.2674 48.5216 34.8721 48.9614C35.3152 49.2926 31.7237 49.7427 30.0713 49.7427H30.0661ZM30.0661 40.207H34.2153V43.3683H25.9221V40.207H30.0661ZM25.9169 38.4841V36.2438C25.9169 35.0951 27.1835 35.2141 27.1835 35.2141H32.9434C32.9434 35.2141 34.2101 35.0951 34.2101 36.2438V38.4841H25.9169ZM50.8434 16.386C33.3657 19.2679 33.9182 33.4395 33.9182 33.4395H26.214C26.214 33.4395 26.7665 19.2679 9.2888 16.386C8.06384 16.1842 21.7781 13.0488 28.5649 13.1161L30.0661 13.0954L31.5673 13.1161C38.3541 13.0488 52.0683 16.1894 50.8434 16.3912H50.8486L50.8434 16.386ZM56.5772 12.1589C56.4417 13.323 55.0134 15.4547 55.0134 15.4547C54.3671 16.2256 53.1943 15.703 53.1943 15.703C42.2948 11.2793 30.0609 11.5949 30.0609 11.5949C30.0609 11.5949 17.827 11.2845 6.93272 15.703C6.93272 15.703 5.7651 16.2256 5.11353 15.4547C5.11353 15.4547 3.68529 13.323 3.55497 12.1589C3.43508 11.1034 4.1075 10.5549 4.1075 10.5549C4.49323 10.2393 12.6457 3.4614 30.0661 3.30618C47.4917 3.4614 55.639 10.2393 56.0247 10.5549C56.0247 10.5549 56.7023 11.0982 56.5772 12.1589Z"
    fill="#903221" />
  <path d="M91.0255 52.833L101.399 0.0117188H92.3786L85.1732 41.1846L78.0168 0.0117188H69.002L79.1959 52.833H91.0255Z"
    fill="white" />
  <path
    d="M155.632 20.2865V12.6174C155.632 8.79393 154.643 5.81644 152.676 3.67932C150.432 1.23611 146.911 0.0117188 142.118 0.0117188H128.561V52.833H137.157V32.9367H142.89L148.106 52.833H157.404L151.035 29.7699C154.1 27.1876 155.632 24.0264 155.632 20.2865ZM147.264 20.459C147.264 21.7224 146.786 22.8021 145.829 23.7037C144.873 24.5997 143.716 25.0505 142.346 25.0505H137.157V8.14834H142.346C145.623 8.14834 147.259 9.67883 147.259 12.7454V20.459H147.264Z"
    fill="white" />
  <path
    d="M176.49 40.3275C176.49 41.5797 176.012 42.6427 175.055 43.5165C174.099 44.3903 172.941 44.8299 171.577 44.8299C170.214 44.8299 169.045 44.3958 168.073 43.5165C167.1 42.6427 166.616 41.5797 166.616 40.3275V0.0117188H158.199V40.4054C158.199 43.8504 159.52 46.7834 162.155 49.2043C164.796 51.6197 168.007 52.833 171.8 52.833C175.593 52.833 178.777 51.6364 181.445 49.2433C184.113 46.8557 185.45 43.9061 185.45 40.411V0.0117188H176.49V40.322V40.3275Z"
    fill="white" />
  <path
    d="M213.274 32.0184C212.122 30.154 210.481 28.2617 208.362 26.3417C207.726 25.7406 206.406 24.6832 204.406 23.1749C202.711 21.9116 201.434 20.8152 200.586 19.8858C198.342 17.4426 197.222 15.0439 197.222 12.6841C197.222 11.1759 197.554 9.91258 198.222 8.89967C199.043 7.63632 200.238 7.00743 201.819 7.00743C203.303 7.00743 204.444 7.4304 205.232 8.28191C206.324 9.46178 206.993 11.1091 207.232 13.2184L215.469 11.8605C215.257 9.61204 214.627 7.62519 213.584 5.89435C212.535 4.16351 211.166 2.82225 209.465 1.865C207.281 0.629479 204.656 0.0117188 201.591 0.0117188C198.527 0.0117188 195.739 0.740787 193.582 2.19892C190.093 4.61431 188.349 8.16504 188.349 12.8622C188.349 15.4668 188.985 17.8878 190.256 20.1084C191.441 22.0841 193.457 24.3214 196.31 26.8203C199.706 29.731 202.254 31.9126 203.955 33.3708C206.107 35.4856 207.188 37.606 207.188 39.7487C207.188 41.2347 206.9 42.37 206.324 43.1659C205.199 44.7854 203.7 45.6202 201.819 45.6759C200.483 45.7315 199.298 45.2473 198.271 44.2289C196.875 42.882 195.994 40.7004 195.63 37.6951L187.441 38.2294C187.626 40.9731 188.251 43.4052 189.332 45.5312C190.408 47.6572 191.875 49.3323 193.723 50.5679C195.935 52.0761 198.728 52.8274 202.097 52.8274C203.917 52.8274 205.509 52.6382 206.873 52.2542C209.421 51.5418 211.394 50.323 212.791 48.5921C214.915 46.0376 215.948 42.9711 215.882 39.3758C215.85 37.9233 215.426 36.2759 214.611 34.4393C214.279 33.6991 213.839 32.8921 213.29 32.0128L213.274 32.0184Z"
    fill="white" />
  <path d="M243.5 0.0117188H216.656V8.02034H225.801V52.833H234.398V8.02034H243.5V0.0117188Z" fill="white" />
  <path
    d="M270.087 44.8212H254.209V30.429H265.811V22.4315H254.209V8.03382H270.087V0.0307617H245.607V52.8242H270.087V44.8212Z"
    fill="white" />
  <path d="M280.799 0.0117188H272.197V52.833H295.634V44.9078H280.799V0.0117188Z" fill="white" />
  <path
    d="M297.742 52.8553H322.222V44.8466H306.339V30.4378H317.945V22.4292H306.339V8.02034H322.222V0.0117188H297.742V52.8553Z"
    fill="white" />
  <path
    d="M352.606 0.0117188H343.439L332.93 18.8562V0.0117188H324.328V52.8775H332.93V36.1145L335.5 31.4117L344.531 52.8775H354.638L340.836 21.5721L352.606 0.0117188Z"
    fill="white" />
  <path
    d="M117.321 0.0117188H104.187L95.0527 52.833H103.291L104.839 43.2494H115.848L117.397 52.833H126.45L117.321 0.0117188ZM105.986 36.0811L110.311 9.36717L114.631 36.0811H105.986Z"
    fill="white" />
  <path
    d="M365.736 0.0639648L356.602 52.8908H364.839L366.388 43.3072H377.397L378.946 52.8908H387.999L378.87 0.0639648H365.736ZM367.529 36.1389L371.854 9.42497L376.174 36.1389H367.529Z"
    fill="white" />
</svg></template>
