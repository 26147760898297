<!-- eslint-disable vue/no-v-html -->
<template>
<div>
  <slot></slot>

  <FooterNodeTitle v-if="item.children.length" :item="item" />

  <div v-else-if="item.name === TYPES.COMPONENT" class="mb-4 lg:mb-6">
    <component :is="component" />
  </div>

  <div v-else-if="item.target_type === TYPES.SOME" class="">
    <FooterNodeTitle v-if="item.children.length == 0" :item="item" />
    <SomeLinks class="flex justify-start" />
  </div>

  <div v-else-if="item.target_type === TYPES.MENU_TEXT" class="">
    <FooterNodeTitle v-if="item.children.length === 0" :item="item" />
    <div class="text-sm" :href="item.link" v-html="item.value"></div>
  </div>

  <a v-else-if="item.target_type === TYPES.MENU_LINK" class="text-sm block mb-2 lg:mb-3 hover:underline"
    :href="item.value">
    {{ item.name }}
  </a>

  <a v-else-if="item.target_type === TYPES.ARTICLE" class="text-sm block mb-2 lg:mb-3 hover:underline"
    :href="item.value">
    {{ item.name }}
  </a>


  <FooterNode v-for="child in item.children" :key="child.id" :item="child" />


</div>
</template>

<script setup>
import { computed, onErrorCaptured, defineAsyncComponent, inject } from "vue";

const FooterNodeTitle = defineAsyncComponent(() => import("@/Components/Menu/FooterNodeTitle.vue"));
const SomeLinks = defineAsyncComponent(() => import("@/Shared/SomeLinks.vue"));
const FooterNewsletter = defineAsyncComponent(() => import("@/Components/Menu/FooterNewsletter.vue"));
// const FooterPaymentMethods = defineAsyncComponent(() => import("@/Components/Menu/FooterPaymentMethods.vue"));
const FooterPaymentMethodsHardCoded = defineAsyncComponent(() => import("@/Components/Menu/FooterPaymentMethodsHardCoded.vue"));
const FooterShippingMethods = defineAsyncComponent(() => import("@/Components/Menu/FooterShippingMethods.vue"));

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  idx: {
    type: Number,
    required: false,
    default: 0,
  },
});

const { TYPES, trim } = inject('Footer', null);

const component = computed(() => {
  if (props.item.name !== TYPES.COMPONENT && props.item.target_type !== TYPES.MENU_TEXT) {
    return;
  }
  const value = trim(props.item.value);
  return {
    shipping_methods: FooterShippingMethods,
    payment_methods: FooterPaymentMethodsHardCoded,
    newsletter: FooterNewsletter,
  }[value];
});

onErrorCaptured((err) => {
  console.error(err);
});

</script>
