<template>
<div class="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-6 opacity-75 text-sm">
  <div>
    {{ footerInfo?.text }}
  </div>
  <div v-if="footerInfo.array.length" class="flex flex-wrap items-center justify-start gap-6">
    <template v-for="item in footerInfo.array" :key="item.id">

      <template v-if="item?.children?.length">
        <template v-for="child in item.children" :key="child.id">

          <component :is="getComponent(child.value)" v-bind="{ ...{ item: item } }"
            v-if="child.name === TYPES.COMPONENT && child.target_type === TYPES.MENU_TEXT" />

          <Link v-else-if="child.target_type == TYPES.MENU_LINK" :href="item.value ?? ''" class="hover:underline">
          {{ child.name }}
          </Link>

        </template>
      </template>

      <Link v-else-if="item.target_type == TYPES.MENU_LINK" :href="item.value ?? ''" class="hover:underline">
      {{ item.name }}
      </Link>

    </template>

  </div>
</div>
</template>

<script setup>
import { computed, inject, defineAsyncComponent } from "vue";

const FooterCookieToggle = defineAsyncComponent(() => import("@/Components/Menu/FooterCookieToggle.vue"));

const { TYPES, store, trim } = inject('Footer', null);

const footerInfo = computed(() => {
  let data = store['footer_info'] ?? null;
  return {
    text: data?.[0]?.value ?? "",
    array: data?.[1]?.children ?? [],
  };
});

const getComponent = (value) => {
  const key = trim(value);
  return {
    change_cookies: FooterCookieToggle,
  }[key];
};


</script>
