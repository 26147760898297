import { shallowRef, onMounted, onBeforeMount, onBeforeUnmount, watch } from "vue";
import { router } from "@inertiajs/vue3";

const SSR = import.meta.env.SSR;

const isLoaded = shallowRef(null);

function setScrollbars(condition) {
  if (typeof document === 'undefined') return; // ssr fix
  // disable scrolling when open
  if (condition) {
    document.body.style.setProperty("overflow", "hidden");
    // document.body.style.setProperty("scrollbar-gutter", "stable");
  } else { // restore srcolling
    document.body.style.removeProperty("overflow");
    // document.body.style.removeProperty("scrollbar-gutter");
  }
  return condition;
}

export function useScrollbar(isOpen, settings = {}) {

  if (!SSR && !isLoaded.value) {

    isLoaded.value = true;

    onMounted(() => {
      router.on("navigate", () => {
        if (typeof document === 'undefined') return; // ssr fix
        document.body.style.removeProperty("overflow");
        document.body.style.removeProperty("scrollbar-gutter");
      });
    });

  }

  const hide = () => isOpen.value = null;

  const handleEsc = (e) => {
    if (isOpen.value && e.code === "Escape") {
      hide();
    }
  };

  onBeforeMount(() => {
    document.addEventListener("keydown", handleEsc);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleEsc);
  });

  watch(isOpen, (newValue) => {

    setScrollbars(newValue);
    // extra function call on open
    if (typeof settings?.callback === 'function') {
      settings.callback();
    }

    // extra function calls on overlay open
    const callbacks = settings?.callbacks ?? {};

    if (Object.keys(callbacks)?.length) {
      for (const key in callbacks) {
        let item = callbacks[key];
        if (typeof item?.fn === 'function' && (item?.condition === undefined || newValue == item?.condition)) {
          item.fn();
        }
      }
    }

  });

  return {
    setScrollbars,
    handleEsc,
    hide,
  };

}
