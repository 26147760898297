<template>
<MetaTags :metadata="metadata" />

<div class="relative flex flex-col min-h-screen justify-between">


  <!-- TODO fix -->
  <!-- TODO drop-shadow on elements HERE breaks several modals and index overlay menus -->

  <template v-if="$page.component !== 'Cart'">
    <div class="bg-accent-space">
      <div class="max-w-large mx-auto bg-white">
        <Nav />
      </div>
    </div>
  </template>

  <div class="bg-accent-space">
    <div class="flex-1 max-w-large mx-auto bg-white">
      <!-- page content -->
      <slot />
    </div>
  </div>

  <div class="bg-accent-space">
    <div class="max-w-large mx-auto bg-white">
      <Footer />
    </div>
  </div>

</div>

<div>
  <CookieConsent v-if="showCookieConsent" />
  <!-- <CookieConsentToggle v-else />  -->

  <!-- refaktoroi käyttään definemodel v-model:show="cartPopupOpen" niin päivittyy molemmista suunnista aina oikein -->
  <CartSidemenu v-model:open="cartPopupOpen" />

  <LoginSidemenu v-model:open="loginSidemenuOpen" />

  <!-- <ScrollTopButton /> -->

</div>
</template>


<script setup>
import { shallowRef, provide, defineAsyncComponent, onMounted } from "vue";
import { useAnalytics } from "g/Composables/Analytics.js";
import { useAccount } from "@/Composables/Account.js";
import { useCookies } from "@/Composables/Cookie.js";
import NProgress from 'nprogress';
import Nav from "@/Shared/Nav.vue";
import Footer from "@/Shared/Footer.vue";
import MetaTags from "@/Components/Layout/MetaTags.vue";
// import ScrollTopButton from "@/Shared/ScrollTopButton.vue";

// import MobileFixedMenu from "@/Components/Nav/MobileFixedMenu.vue";

const CartSidemenu = defineAsyncComponent(() => import("@/Shared/CartSidemenu.vue"));
const LoginSidemenu = defineAsyncComponent(() => import("@/Shared/LoginSidemenu.vue"));
const CookieConsent = defineAsyncComponent(() => import("@/Shared/CookieConsent.vue"));


defineProps({
  metadata: {
    type: Object,
    required: false,
    default() {
      return {
        title: "",
        metatags: [],
      };
    },
  },
});

// delete inertia data-page attribute on load
if (typeof document !== "undefined") {
  document.getElementById("app").removeAttribute("data-page");
}

const { setUserType, isLoggedIn } = useAccount();

const { showCookieConsent } = useCookies();

useAnalytics();

setUserType();

// --------- voidaan sulkea cart sidemenu mistä tahansa
const cartPopupOpen = shallowRef(false);

function toggleCartSidemenu(open) {
  if (open === undefined) {
    cartPopupOpen.value = !cartPopupOpen.value;
  } else {
    cartPopupOpen.value = !!open;
  }
}

provide('toggleCartSidemenu', toggleCartSidemenu);

// --------- voidaan sulkea login sidemenu mistä tahansa
const loginSidemenuOpen = shallowRef(false);
function toggleLoginSidemenu(open) {
  if (isLoggedIn.value) return false;
  if (open === undefined) {
    loginSidemenuOpen.value = !loginSidemenuOpen.value;
  } else {
    loginSidemenuOpen.value = !!open;
  }
}

provide('toggleLoginSidemenu', toggleLoginSidemenu);

onMounted(() => {
  // Configure NProgress settings here
  NProgress.configure({
    easing: 'ease',
    speed: 500,
    showSpinner: true,
  });
});

// ---------

</script>
