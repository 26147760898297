import { usePage } from "@inertiajs/vue3";
import { shallowReactive, toRaw, watchEffect } from 'vue';

const $page = usePage();

// STORE
const data = shallowReactive({
  infobar: [],
  countries: [],
  promos: [],
  notifications: [],
  currentLocale: undefined,
});

const handler = {
  get(target, prop) {
    return target[prop] ? target[prop] : [];
  },
};

const store = new Proxy(data, handler);

const updateStore = () => {

  const props = $page.props;

  // console.log('update');

  if (props) {

    if (data.currentLocale === props?.locale) {
      return false;
    }

    data.currentLocale = props?.locale;

    // console.log("updateStore", props?.locale);

    const local = {};

    for (let key of Object.keys(data)) {
      if (Object.getOwnPropertyDescriptor(props, key)) {
        local[key] = toRaw(props[key] ?? []);
      }
    }
    // props menus array
    if (props.menus) {
      for (let key of Object.keys(props.menus)) {
        local[key] = toRaw(props.menus[key] ?? []);
      }
    }

    Object.assign(data, local);
  }
};

// Refresh data when locale changes
watchEffect(() => {

  var update = null;

  if (
    $page.props?.locale !== data.currentLocale ||
    $page.props?.flash?.event === 'login'
  ) {
    update = true;
  }

  if (update) {
    updateStore();
  }

});

export const useStore = () => {
  // console.log("-------- useStore --------");
  return store;
};
