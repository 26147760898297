import { usePage } from '@inertiajs/vue3';
import { shallowRef, shallowReactive, onBeforeMount } from 'vue';

// statics
const desktop = 'Desktop';

const tablet = 'Tablet';

const mobile = 'Mobile';

// breakpoints same as tailwind
const breakpoints = {
  'xs': '(min-width: 0px) and (max-width: 640px)',
  'sm': '(min-width: 640px) and (max-width: 768px)',
  'md': '(min-width: 768px) and (max-width: 1024px)',
  'lg': '(min-width: 1024px)',
  // 'xl': '(min-width: 1280px)',
  // 'xl2': '(min-width: 1536px)',
};

const isLoaded = shallowRef(null);

const deviceType = shallowRef(null);

const deviceObject = shallowReactive({});

const handler = {
  get(target, prop) {
    return target[prop] ? target[prop] : false;
  },
};

const device = new Proxy(deviceObject, handler);

const setDevice = (newValue) => {

  let newSet = {
    isMobile: newValue === mobile,
    isTablet: newValue === tablet,
    isDesktop: newValue === desktop,
    isMobileOrTablet: newValue === (mobile || tablet),
    isDesktopOrTablet: newValue === (desktop || tablet),
  };

  Object.assign(deviceObject, newSet);

  deviceType.value = newValue;
};

export const useDevice = () => {

  if (!deviceType.value) {
    setDevice(usePage().props?.device);
  }

  const mediaQuery = (e) => {

    if (e.matches) {

      let type;

      switch (e.media) {
        // case breakpoints.xl2:
        // case breakpoints.xl:
        case breakpoints.lg:
          type = desktop;
          break;
        case breakpoints.md:
          type = tablet;
          break;
        default:
          type = mobile;

      }

      if (type !== deviceType.value) {
        setDevice(type);
      }

    }

  };

  const mediaEventListeners = () => {

    const isSupported = window && 'matchMedia' in window && typeof window.matchMedia === 'function';

    if (!isSupported) return;

    for (const key in breakpoints) {
      let item = window.matchMedia(breakpoints[key]);
      if (!isLoaded.value) {
        item.addEventListener('change', mediaQuery);
        mediaQuery(item); // inital query - fixes layout but adds ssr mismatch?
      } else {
        item.removeEventListener('change', mediaQuery);
      }
    }

    isLoaded.value = !isLoaded.value;

  };

  onBeforeMount(() => {
    if (!isLoaded.value) {
      // console.log('mount');
      mediaEventListeners();
    }
  });

  /* HOX: This causes media eventListeners to be destroyed and added unnecessarily */
  // onBeforeUnmount(() => {
  //   if (isLoaded.value) {
  //     console.log('unmount');
  //     mediaEventListeners();
  //   }
  // });

  return { device };

};
