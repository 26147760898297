'use strict';

class Event {

  constructor(event) {
    this.data = { type: event };
  }

  reset() {
    this.data = {};
  }

  append(data = {}) {
    Object.assign(this.data, data);
  }

  push(data = null) {
    if (window?.cstbr) {
      if (data) {
        Object.assign(this.data, data);
      }
      window.cstbr.push(this.data);
      this.reset();
    }
  }

}

export class custobarEvents {

  constructor() {

    this.page = {
      'view': [
        new Event("BROWSE"),
        new Event("VIEW"),
      ],
      labels(component) {
        return {
          'Frontpage': "Frontpage",
          'Cart': "Cart",
          'Product': "Product page",
          'Confirmation': "Confirmation page",
        }[component];
      },
    };

    this.wishlist = {
      'add': new Event("WISHLIST_ADD"),
      'remove': new Event('WISHLIST_REMOVE'),
    };

    this.cart = {
      'add': [
        new Event("CART_ADD"),
        new Event("BASKET_ADD"),
      ],
      'remove': new Event("CART_REMOVE"),
      'empty': new Event("EMPTY_CART"),

    };

  }

}
