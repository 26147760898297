<template><!-- MOBILE -->


  <span @click="toggle">
    <SearchIcon />
  </span>

  <div v-if="searchbarVisible" class="fixed inset-0  opacity-0 h-full w-full z-10" @click="toggle"></div>
  <div v-show="searchbarVisible" class="absolute left-0  top-8 lg:top-2 w-full  lg:w-auto lg:left-auto  lg:right-0 mt-2 py-2 z-90 z-[9999]">
    <div class="pt-2 relative">
      <div class="mx-auto text-gray-600 ">
        <input id="klevusearch" ref="searchInput" v-model="searchTerm"
          class="border-gray-300 bg-white h-10 px-5 w-full lg:w-auto pr-12 text-base focus:bg-white focus:border-black focus:outline-none focus:ring-black "
          type="search" name="klevusearch" :placeholder="trans('search')" autocomplete="off" enterkeyhint="search"
          @keyup.enter="search" />
        <button class="absolute right-0 top-2 w-10 h-10" @click="search">
          <SearchIcon class="text-gray-600 h-5 w-5" />
        </button>
      </div>
    </div>
  </div>

</template>

<script setup>
import SearchIcon from "@/Icons/SearchIcon.vue";
import { shallowRef, inject, nextTick, onBeforeMount } from "vue";


defineProps({
  isMobile: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(['close']);

const searchInput = shallowRef(null);

const { searchTerm, searchbarVisible } = inject('SearchDropdown', {
  searchTerm: shallowRef(""),
});

function search() {
  if (searchTerm.value.length >= 3) {
    window.location.href = '/search?q=' + searchTerm.value; // to close Klevu search suggestions
  }
}


function toggle() {
  searchbarVisible.value = !searchbarVisible.value;
  if (searchbarVisible.value) {
    focus();
  }
}

async function focus() {
  await nextTick();
  if (searchInput.value) {
    searchInput.value.focus();
  }
}

onBeforeMount(() => focus());

</script>
