<template>
<svg  width="28" height="28" viewBox="0 0 81 86" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5010_15181)">
<path d="M7.42 28.9C8.58 30.3 11.47 31.07 15.6 31.07H67.63C67.99 30.7 68.34 30.32 68.68 29.93C71.05 27.23 72.72 24.35 73.6 21.57C74.37 19.09 74.45 16.76 73.83 14.82C73.14 12.8 71.67 11.09 69.43 9.81C66.27 7.95 61.79 6.98 56.08 6.82H54.19L54.15 6.78C43.85 6.78 33.05 8.88 23.72 12.64C17.28 15.25 12.06 18.47 9.2 21.45C7.91 22.8 7.1 24.1 6.88 25.28C6.61 26.87 6.76 28.11 7.42 28.89V28.9ZM54.7 26.93C54.89 26.62 54.93 26.27 54.93 25.96V25.57H57.9V25.88L57.98 25.92C57.98 26.27 58.02 26.62 58.21 26.93C57.05 27.2 55.9 27.2 54.7 26.93ZM57.86 23.9V24.95H54.89V23.9H57.86ZM54.89 23.32V22.47C54.89 22.31 55.08 22.24 55.31 22.24H57.39C57.7 22.24 57.85 22.36 57.85 22.67V23.33H54.88L54.89 23.32ZM63.61 15.99L63.53 16.03C63.34 16.07 63.11 16.11 62.91 16.15C61.83 16.38 60.79 16.81 59.9 17.47C58.67 18.36 57.97 19.57 57.74 21C57.7 21.16 57.7 21.43 57.7 21.43V21.62H54.92V21.12C54.8 19.99 54.34 18.99 53.53 18.13C52.57 17.08 51.29 16.5 49.87 16.15C49.64 16.11 49.37 16.03 49.14 15.99C49.02 15.95 48.91 15.91 48.79 15.91C53.84 14.47 58.86 14.47 63.95 15.91C63.83 15.95 63.6 15.99 63.6 15.99H63.61ZM46.83 14.09C46.91 14.01 46.98 13.97 47.06 13.93C48.29 13.11 49.64 12.61 51.07 12.26C52.81 11.83 54.54 11.64 56.35 11.64L56.39 11.56C58.63 11.56 60.86 11.87 63.02 12.61C63.95 12.92 64.87 13.35 65.68 13.89C65.95 14.08 66.1 14.36 65.95 14.67C65.8 14.98 65.64 15.29 65.45 15.6C65.33 15.79 65.1 15.83 64.79 15.72C63.32 15.18 61.82 14.83 60.24 14.63C59.51 14.51 58.77 14.47 58.04 14.4C57.38 14.36 56.69 14.32 56 14.36C54.5 14.36 52.99 14.52 51.53 14.79C50.3 15.02 49.1 15.29 47.94 15.76C47.82 15.8 47.71 15.8 47.55 15.8C47.39 15.8 47.28 15.72 47.2 15.61C47.16 15.53 47.12 15.45 47.05 15.38C47.05 15.38 46.82 14.99 46.74 14.8C46.62 14.57 46.62 14.33 46.82 14.1L46.83 14.09Z" fill="#903221"/>
<path d="M70.32 62.95C73.06 62.64 75.57 61.4 77.49 59.42C79.8 57.06 80.97 53.98 81 50.88V50.67C80.98 47.58 79.82 44.5 77.49 42.14H77.53C76.06 40.67 74.29 39.58 72.32 39C72.32 38.03 72.28 37.1 72.2 36.17C75.98 32.29 78.68 27.98 80.03 23.67C80.62 21.82 80.94 19.98 80.99 18.21V17.29C80.96 15.68 80.71 14.13 80.22 12.68C79.02 8.99 76.52 6.04 72.78 3.87C68.65 1.46 63.1 0.18 56.23 -0.0100002H52.66C41.98 0.19 30.93 2.37 21.25 6.32C9.41 11.14 1.39 17.93 0.23 24.1C0.08 24.92 0.01 25.7 0 26.46V27.03C0.05 29.45 0.79 31.56 2.24 33.3C4.28 35.78 7.64 37.3 12.19 37.76V39C10.22 39.58 8.41 40.67 6.98 42.14C2.31 46.91 2.35 54.68 6.98 59.41C8.95 61.39 11.42 62.59 14.15 62.94C15.96 68.33 19.2 73.5 23.29 77.53C28.92 83.08 35.48 85.99 42.23 85.99C48.98 85.99 55.54 83.08 61.17 77.53C65.26 73.49 68.5 68.33 70.31 62.94L70.32 62.95ZM72.44 48.16V46.69C72.52 46.77 72.63 46.85 72.71 46.96C74.79 49.09 74.75 52.51 72.71 54.64C72.48 54.87 72.25 55.07 71.98 55.26C72.25 53.09 72.37 50.8 72.44 48.16ZM11.77 54.6C9.69 52.5 9.69 49.05 11.77 46.92C11.85 46.84 11.96 46.76 12.04 46.65V48.12C12.12 50.76 12.27 53.09 12.5 55.22C12.23 55.03 12 54.83 11.77 54.6ZM7.41 28.91C6.75 28.13 6.6 26.89 6.87 25.3C7.09 24.12 7.9 22.82 9.2 21.46C12.06 18.48 17.28 15.26 23.72 12.65C33.05 8.89 43.85 6.79 54.15 6.79L54.19 6.83H56.08C61.79 6.99 66.26 7.96 69.43 9.82C71.67 11.1 73.13 12.81 73.83 14.83C74.45 16.77 74.37 19.1 73.6 21.58C72.72 24.36 71.05 27.24 68.68 29.94C68.34 30.33 67.99 30.71 67.62 31.09H15.58C11.45 31.09 8.56 30.31 7.4 28.92L7.41 28.91ZM28.01 72.65C20.64 65.39 19.02 57.55 18.79 48.01C18.71 44.21 18.75 40.83 18.91 37.92H65.5C65.65 40.87 65.73 44.25 65.62 48.01H65.7C65.51 57.56 63.85 65.4 56.48 72.65C47.57 81.42 36.92 81.42 28.01 72.65Z" fill="#1C1B1F"/>
<path d="M48.79 15.91C48.91 15.91 49.02 15.95 49.14 15.99C49.37 16.03 49.64 16.11 49.87 16.15C51.3 16.5 52.57 17.08 53.53 18.13C54.34 18.98 54.8 19.99 54.92 21.12V21.62H57.7V21.43C57.7 21.43 57.7 21.16 57.74 21C57.97 19.56 58.67 18.36 59.9 17.47C60.79 16.81 61.83 16.38 62.91 16.15C63.1 16.11 63.33 16.07 63.53 16.03L63.61 15.99C63.61 15.99 63.84 15.95 63.96 15.91C58.87 14.47 53.85 14.47 48.8 15.91H48.79Z" fill="white"/>
<path d="M47.06 15.37C47.14 15.45 47.18 15.53 47.21 15.6C47.29 15.72 47.4 15.79 47.56 15.79C47.72 15.79 47.83 15.79 47.95 15.75C49.11 15.28 50.3 15.01 51.54 14.78C53.01 14.51 54.51 14.35 56.01 14.35C56.7 14.31 57.4 14.35 58.05 14.39C58.78 14.47 59.52 14.51 60.25 14.62C61.83 14.81 63.34 15.16 64.8 15.71C65.11 15.83 65.34 15.79 65.46 15.59C65.65 15.28 65.81 14.97 65.96 14.66C66.11 14.35 65.96 14.08 65.69 13.88C64.88 13.34 63.95 12.91 63.03 12.6C60.87 11.86 58.63 11.55 56.4 11.55L56.36 11.63C54.55 11.63 52.81 11.82 51.08 12.25C49.65 12.6 48.3 13.1 47.07 13.92C46.99 13.96 46.92 14 46.84 14.08C46.65 14.31 46.65 14.55 46.76 14.78C46.84 14.97 47.07 15.36 47.07 15.36L47.06 15.37Z" fill="white"/>
<path d="M57.9 25.89V25.58H54.93V25.97C54.93 26.28 54.89 26.63 54.7 26.94C55.9 27.21 57.05 27.21 58.21 26.94C58.02 26.63 57.98 26.28 57.98 25.93L57.9 25.89Z" fill="white"/>
<path d="M57.86 23.09V22.66C57.86 22.35 57.71 22.23 57.4 22.23H55.32C55.09 22.23 54.9 22.31 54.9 22.46V23.31H57.87V23.08L57.86 23.09Z" fill="white"/>
<path d="M57.86 23.91H54.89V24.96H57.86V23.91Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_5010_15181">
<rect width="81" height="86" fill="white"/>
</clipPath>
</defs>
</svg>


</template>
