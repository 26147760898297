<template><svg width="26" height="20" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_3267_1093)">
    <path d="M18 0H0V2H18V0Z" fill="#1C1B1F" />
    <path d="M18 6H0V8H18V6Z" fill="#1C1B1F" />
    <path d="M18 12H0V14H18V12Z" fill="#1C1B1F" />
  </g>
  <defs>
    <clipPath id="clip0_3267_1093">
      <rect width="26" height="20" fill="white" />
    </clipPath>
  </defs>
</svg></template>
