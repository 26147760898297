<template>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1805_5786" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="10" height="10">
      <rect x="10" width="10" height="10" transform="rotate(90 10 0)" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_1805_5786)">
      <path d="M0.97663 3.33987L1.56796 2.74853L4.99906 6.17963L8.43015 2.74854L9.02148 3.33987L4.99906 7.3623L0.97663 3.33987Z" fill="currentColor"/>
    </g>
  </svg>
</template>
