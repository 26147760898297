import { shallowRef, onBeforeMount } from "vue";
import { router } from "@inertiajs/vue3";

// const SSR = import.meta.env.SSR;

const storage = {
  set: (key, value) => localStorage.setItem(key, value),
  get: (key) => localStorage.getItem(key),
};

const showCookieConsent = shallowRef(null);
const isLoaded = shallowRef(null);

const gsend = (type, value) => {
  if (typeof consent === 'function') {
    window.consent(type, value);
  }
};

const openCookieModal = () => {
  showCookieConsent.value = true;
  storage.set('showCookieConsent', true);
};

const closeCookieModal = (bool) => {

  showCookieConsent.value = false;
  storage.set('showCookieConsent', false);

  storage.set('cookieConsent', bool);

  if (typeof fbq === 'function') {
    window.fbq('consent', bool ? "grant" : "revoke");
  }

  if (typeof gtag === 'function') {
    const value = bool ? "granted" : "denied";
    gsend('update', value);
  }

  window.axios.post('/consent', { consent: (bool ? 1 : 0) })
    .catch((error) => {
      console.error(error);
    })
    .then(() => {
      // console.log(response.data);
    });

};

const useCookies = () => {

  onBeforeMount(() => {

    if (!isLoaded.value) {

      showCookieConsent.value = !storage.get('showCookieConsent') || storage.get("showCookieConsent") === "true";

      router.on('navigate', () => {

        if (isLoaded.value && typeof gtag === 'function') {

          const value = localStorage.getItem('cookieConsent') === "true" ? "granted" : "denied";
          gsend('default', value);

          // LEKA CUSTOM
          window.gtag({ event: 'default_consent' });
        }

        // This last here so consent doesnt tigger twice once in app.blade at refresh and the again at inital navigation
        isLoaded.value = true;

      });

    }

  });

  return {
    showCookieConsent,
    closeCookieModal,
    openCookieModal,
  };
};

export {
  useCookies,
};
